// extracted by mini-css-extract-plugin
export var root = "PlasmicPricing-module--root--Kf0oH";
export var box___0ZwYz = "PlasmicPricing-module--box___0ZwYz--1rc9I";
export var header = "PlasmicPricing-module--header--3rvc5";
export var section__fY6PC = "PlasmicPricing-module--section__fY6PC--aX94r";
export var box__epj9V = "PlasmicPricing-module--box__epj9V--9FHsr";
export var plan__iT48V = "PlasmicPricing-module--plan__iT48V--llPai";
export var box__tdyXb = "PlasmicPricing-module--box__tdyXb--xobe3";
export var box__rc3Dv = "PlasmicPricing-module--box__rc3Dv--2Jtgf";
export var bullet__waPga = "PlasmicPricing-module--bullet__waPga--xvefl";
export var bullet__j4Fb1 = "PlasmicPricing-module--bullet__j4Fb1--1dHVH";
export var bullet__crCpo = "PlasmicPricing-module--bullet__crCpo--1bEW1";
export var plan__pXCfW = "PlasmicPricing-module--plan__pXCfW--3pBsH";
export var box__dS5Oo = "PlasmicPricing-module--box__dS5Oo--1bLA2";
export var box__aK1Fm = "PlasmicPricing-module--box__aK1Fm--2fgXI";
export var bullet__tmKt8 = "PlasmicPricing-module--bullet__tmKt8--4kLoe";
export var bullet__jo1T8 = "PlasmicPricing-module--bullet__jo1T8--2nTiy";
export var bullet__vAsDu = "PlasmicPricing-module--bullet__vAsDu--38GKa";
export var bullet__vkjpa = "PlasmicPricing-module--bullet__vkjpa--15NB5";
export var plan__vu5Ja = "PlasmicPricing-module--plan__vu5Ja--2nPQc";
export var box___5ObUk = "PlasmicPricing-module--box___5ObUk--1XQiy";
export var box__p37IR = "PlasmicPricing-module--box__p37IR--37P55";
export var bullet__t1PoJ = "PlasmicPricing-module--bullet__t1PoJ--M3Tvq";
export var bullet___3MEdw = "PlasmicPricing-module--bullet___3MEdw--16JKT";
export var bullet__hsyBt = "PlasmicPricing-module--bullet__hsyBt--1A1AX";
export var section__gvjLg = "PlasmicPricing-module--section__gvjLg--2wecU";
export var faq__zDxBh = "PlasmicPricing-module--faq__zDxBh--3mP51";
export var faq__nDeV = "PlasmicPricing-module--faq__nDeV--2MvPO";
export var faq__mJ1Zj = "PlasmicPricing-module--faq__mJ1Zj--18gbK";
export var footer = "PlasmicPricing-module--footer--zhosD";