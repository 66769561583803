// extracted by mini-css-extract-plugin
export var root = "PlasmicPlan-module--root--1Vhcn";
export var box__c5Ffi = "PlasmicPlan-module--box__c5Ffi--25L9n";
export var box___9Bzpq = "PlasmicPlan-module--box___9Bzpq--2n4RR";
export var slotName = "PlasmicPlan-module--slotName--3Vdmj";
export var box___8676P = "PlasmicPlan-module--box___8676P--1nxTx";
export var box__feOFl = "PlasmicPlan-module--box__feOFl--1uewC";
export var bullet__p0Wox = "PlasmicPlan-module--bullet__p0Wox--Exwd3";
export var bullet__yIaCz = "PlasmicPlan-module--bullet__yIaCz--20p5u";
export var bullet___94Ca = "PlasmicPlan-module--bullet___94Ca--2IEEO";
export var box__mveBy = "PlasmicPlan-module--box__mveBy--3ZLWm";
export var svg__f97X6 = "PlasmicPlan-module--svg__f97X6--hRsVF";