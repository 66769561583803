// extracted by mini-css-extract-plugin
export var root = "PlasmicTopSection-module--root--2B2t-";
export var box__ihLjy = "PlasmicTopSection-module--box__ihLjy--gqZxF";
export var box__t5JL6 = "PlasmicTopSection-module--box__t5JL6--36XUX";
export var box__pZ6Uu = "PlasmicTopSection-module--box__pZ6Uu--6M138";
export var box___1PuMl = "PlasmicTopSection-module--box___1PuMl--2Cfeu";
export var box__sNYgu = "PlasmicTopSection-module--box__sNYgu--2T_oM";
export var box___4JaR8 = "PlasmicTopSection-module--box___4JaR8--2BiUq";
export var box__cEuKs = "PlasmicTopSection-module--box__cEuKs--24hyq";
export var linkButton___6Q0Qk = "PlasmicTopSection-module--linkButton___6Q0Qk--BGjqV";
export var linkButton__wewla = "PlasmicTopSection-module--linkButton__wewla--KrifW";
export var box__jvNzy = "PlasmicTopSection-module--box__jvNzy--UQObh";
export var img = "PlasmicTopSection-module--img--TX3Qd";