// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatures-module--root--2CVwz";
export var box__tvEfa = "PlasmicFeatures-module--box__tvEfa--1k-8-";
export var header = "PlasmicFeatures-module--header--aLgU4";
export var section = "PlasmicFeatures-module--section--YTT7P";
export var box__vWAfE = "PlasmicFeatures-module--box__vWAfE--2ZAb8";
export var box__c4QGj = "PlasmicFeatures-module--box__c4QGj--2yN4c";
export var box__oy9Lu = "PlasmicFeatures-module--box__oy9Lu--1_-eQ";
export var box__nEeq7 = "PlasmicFeatures-module--box__nEeq7--H24v3";
export var bullet__eNkXc = "PlasmicFeatures-module--bullet__eNkXc--S4zPX";
export var bullet___4KKil = "PlasmicFeatures-module--bullet___4KKil---Pkgs";
export var bullet__uzKl0 = "PlasmicFeatures-module--bullet__uzKl0--1F_41";
export var box__k1KWu = "PlasmicFeatures-module--box__k1KWu--2Uxwe";
export var box__lz7Us = "PlasmicFeatures-module--box__lz7Us--3Tt6M";
export var box__f8NjD = "PlasmicFeatures-module--box__f8NjD--1KiHE";
export var img__ecbhA = "PlasmicFeatures-module--img__ecbhA--1Hbcl";
export var img__a5Tek = "PlasmicFeatures-module--img__a5Tek--2yhuw";
export var homeCta = "PlasmicFeatures-module--homeCta--_VZ3o";
export var footer = "PlasmicFeatures-module--footer--3Mue7";