// extracted by mini-css-extract-plugin
export var root = "PlasmicFooter-module--root--2Nkxw";
export var box___6YUvI = "PlasmicFooter-module--box___6YUvI--2btjg";
export var box__h1Ag0 = "PlasmicFooter-module--box__h1Ag0--3Zpu8";
export var box__yrJTu = "PlasmicFooter-module--box__yrJTu--3RIrN";
export var iconLink__j1K9V = "PlasmicFooter-module--iconLink__j1K9V--1_gJ0";
export var svg__efc9A = "PlasmicFooter-module--svg__efc9A--3Ravq";
export var box__gpHQe = "PlasmicFooter-module--box__gpHQe--7QLtc";
export var link__hIbCd = "PlasmicFooter-module--link__hIbCd--3lWJT";
export var box___7KdmC = "PlasmicFooter-module--box___7KdmC--TnJo_";
export var link__rTm = "PlasmicFooter-module--link__rTm--PrKjr";
export var box__z8Ne1 = "PlasmicFooter-module--box__z8Ne1--1I4sl";
export var box__bum4Y = "PlasmicFooter-module--box__bum4Y--zelCg";
export var box__ik0S3 = "PlasmicFooter-module--box__ik0S3--3UQlW";
export var link__c8Cr5 = "PlasmicFooter-module--link__c8Cr5--3ciS0";
export var link__jNYul = "PlasmicFooter-module--link__jNYul--bngKm";
export var link__yPyXc = "PlasmicFooter-module--link__yPyXc--ewEqd";
export var link__wo0JV = "PlasmicFooter-module--link__wo0JV--a_XZ0";
export var link___0Pr8C = "PlasmicFooter-module--link___0Pr8C--2IfYM";
export var box__fwZzI = "PlasmicFooter-module--box__fwZzI--2Jyyy";
export var box__cYvZc = "PlasmicFooter-module--box__cYvZc--25EUV";
export var link___9Sbyu = "PlasmicFooter-module--link___9Sbyu--37Hni";
export var link__mDkgw = "PlasmicFooter-module--link__mDkgw--2tbYJ";
export var link__hzjRh = "PlasmicFooter-module--link__hzjRh--2dNww";
export var link___7JQ1M = "PlasmicFooter-module--link___7JQ1M--1e54I";
export var link__fMiWb = "PlasmicFooter-module--link__fMiWb--32rEV";
export var box__w4RAh = "PlasmicFooter-module--box__w4RAh--2prLB";
export var box__fDKeK = "PlasmicFooter-module--box__fDKeK--2PQas";
export var link___8Dt4U = "PlasmicFooter-module--link___8Dt4U--3UlZL";
export var link___0LXfS = "PlasmicFooter-module--link___0LXfS--Y41AL";
export var link__lar19 = "PlasmicFooter-module--link__lar19--_fWa7";
export var link__dlzNv = "PlasmicFooter-module--link__dlzNv--1XbVQ";
export var link__gWaNh = "PlasmicFooter-module--link__gWaNh--3VB6C";
export var box__a3JmN = "PlasmicFooter-module--box__a3JmN--2l69H";
export var box__xgSta = "PlasmicFooter-module--box__xgSta--34ybm";
export var box__mbWHf = "PlasmicFooter-module--box__mbWHf--1Q32X";
export var box__vW7U = "PlasmicFooter-module--box__vW7U--kj2iK";
export var textbox = "PlasmicFooter-module--textbox--2iVbX";
export var button = "PlasmicFooter-module--button--Lj5uW";
export var box__xFfoD = "PlasmicFooter-module--box__xFfoD--anOu5";
export var box__tRx3A = "PlasmicFooter-module--box__tRx3A--3YZsL";
export var box__aD0J4 = "PlasmicFooter-module--box__aD0J4--1qqoB";
export var link__szkEh = "PlasmicFooter-module--link__szkEh--3IuwJ";
export var link__zjJv = "PlasmicFooter-module--link__zjJv--dZsWF";
export var box__dDuU = "PlasmicFooter-module--box__dDuU--3xTVW";
export var box__l6O9T = "PlasmicFooter-module--box__l6O9T--3xByP";
export var iconLink__z2Qn = "PlasmicFooter-module--iconLink__z2Qn--2ULLi";
export var svg__fmSad = "PlasmicFooter-module--svg__fmSad--3ggmR";
export var iconLink__oflax = "PlasmicFooter-module--iconLink__oflax--1kF-8";
export var svg___77J7I = "PlasmicFooter-module--svg___77J7I--1Rd3h";
export var iconLink___83JwZ = "PlasmicFooter-module--iconLink___83JwZ--2QXJp";
export var svg___5Hbvo = "PlasmicFooter-module--svg___5Hbvo--1Adt3";