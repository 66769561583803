// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatureCard-module--root--2o9Zx";
export var root__long = "PlasmicFeatureCard-module--root__long--1alJX";
export var box__z2VKw = "PlasmicFeatureCard-module--box__z2VKw--8F28P";
export var box___9FpJ = "PlasmicFeatureCard-module--box___9FpJ--39PZt";
export var svg__dDawD = "PlasmicFeatureCard-module--svg__dDawD--2Tz_G";
export var box__kctyM = "PlasmicFeatureCard-module--box__kctyM--35TLx";
export var box__long__kctyMWEkU = "PlasmicFeatureCard-module--box__long__kctyMWEkU--yRKth";
export var slotTitle = "PlasmicFeatureCard-module--slotTitle--f-M6A";
export var slotDescription = "PlasmicFeatureCard-module--slotDescription--ek_se";
export var slotDescription__long = "PlasmicFeatureCard-module--slotDescription__long--1CZ_e";
export var box__oEHl = "PlasmicFeatureCard-module--box__oEHl--3laRH";
export var box__long__oEHlWEkU = "PlasmicFeatureCard-module--box__long__oEHlWEkU--OX1_n";
export var svg__zUpcG = "PlasmicFeatureCard-module--svg__zUpcG--288FI";